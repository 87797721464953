@import "~@nutkit/styles/src/base/nutmeg-gbr-bigbear/tokens.scss";$product: nutmeg-gbr-bigbear !global;
@import '@nutkit/styles/src/base/mixins/breakpoints.scss';

.support-panel {
  padding: 0;
  margin-bottom: 0;
  background-color: var(--nk-color-white);
  border-radius: 0;

  &[class*="nk-panel"] {
    padding: var(--nk-panel-space-inner);
  }

  @include nk-tablet {
    &[class*="nk-panel"] {
      padding: var(--nk-space-stack-lg) 5rem;
    }
  }

  @include nk-desktop {
    background-color: var(--nk-background-color-base);
    box-shadow: none;
    border: 1px solid var(--nk-panel-border-color);
    margin-bottom: var(--nk-space-stack-xl);
    border-radius: var(--nk-panel-border-radius);

    &[class*="nk-panel"] {
      padding: var(--nk-panel-space-inner);
    }
  }
}

.support-detail-container,
.support-detail-item {
  display: flex;
}

.support-detail-container {
  flex-direction: column;
  gap: var(--nk-space-stack-md);

  @include nk-desktop {
    flex-direction: row;
  }
}

.support-detail-item {
  color: var(--nk-text-color-primary);
  gap: var(--nk-space-stack-sm);
  width: 50%;
  margin-bottom: 0;
}
