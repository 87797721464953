@import "~@nutkit/styles/src/base/nutmeg-gbr-bigbear/tokens.scss";$product: nutmeg-gbr-bigbear !global;
@import '@nutkit/styles/src/base/mixins/breakpoints.scss';

.container {
  min-height: 80vh;

  @include nk-desktop {
    min-height: 100vh;
  }

  height: 100%;
  background-image: url("https://images.prismic.io/marketingwebsite/Z5OeZpbqstJ990nL_76946dcbc76e2fb0339bf6f671c3b5ff.jpg?auto=format,compress");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.banner-container {
  background-color: inherit;
  height: 100%;
  max-width: 100%;

  @include nk-only-mobile {
    padding: var(--nk-grid-dynamic-container-inset-md);
  }

  @include nk-small-tablet {
    padding: var(--nk-grid-dynamic-container-inset-lg);
  }

  @include nk-tablet {
    padding: 5rem;
  }

  @include nk-desktop {
    padding: var(--nk-grid-dynamic-container-inset-lg);
  }

  @include nk-desktop-large {
    padding: 6.25rem;
  }
}

.divider {
  border-color: var(--nk-color-grey-xx-light);
  margin-bottom: var(--nk-space-stack-lg);
}

.footer {
  display: flex;
  padding: var(--nk-space-inset-lg) 0;
  align-items: center;
  justify-content: center;

  @include nk-desktop {
    justify-content: flex-start;
  }
}

.logo-divider {
  border-left: 1px solid var(--nk-color-black);
  height: var(--nk-space-stack-xl);
  margin: 0 var(--nk-space-stack-md);
}
