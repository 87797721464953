@import "~@nutkit/styles/src/base/nutmeg-gbr-bigbear/tokens.scss";$product: nutmeg-gbr-bigbear !global;
@import '@nutkit/styles/src/base/mixins/breakpoints.scss';

.container {
  padding: 0;
  margin: 0;
  max-width: none;

  @include nk-desktop {
    padding: var(--nk-grid-dynamic-container-inset-lg);
    max-width: var(--nk-grid-max-width-lg);
  }

  @include nk-desktop-large {
    padding: 4.25rem 6.25rem;
    max-width: var(--nk-grid-max-width-xl);
  }
}

.support-notification {
  background-color: var(--nk-background-color-base);
  box-shadow: none;
  border: 1px solid var(--nk-panel-border-color);
  margin-bottom: var(--nk-space-stack-xl);
}

.required-item-list-container {
  background-color: var(--nk-background-color-base);
  padding: var(--nk-space-inset-lg);
  margin-bottom: 0;

  @include nk-tablet {
    padding: var(--nk-space-inset-lg) 5rem;
  }

  @include nk-desktop {
    background-color: var(--nk-color-white);
    padding: var(--nk-space-inset-lg) 0;
    margin-bottom: var(--nk-space-stack-xl);
  }
}
