@import "~@nutkit/styles/src/base/nutmeg-gbr-bigbear/tokens.scss";$product: nutmeg-gbr-bigbear !global;
@import '@nutkit/styles/src/base/mixins/breakpoints.scss';

.button-group {
  flex-direction: column;

  @include nk-desktop {
    flex-direction: row;
  }
}
