@import "~@nutkit/styles/src/base/nutmeg-gbr-bigbear/tokens.scss";$product: nutmeg-gbr-bigbear !global;
@import '@nutkit/styles/src/base/mixins/breakpoints.scss';

.container {
  padding: var(--nk-space-inset-lg);

  @include nk-tablet {
    padding: var(--nk-space-inset-lg) 5rem;
  }

  @include nk-desktop {
    padding: 0;
  }
}
